import { ReactElement } from 'react';
import Tooltip from 'src/components/common/Tooltip';
import { OneOf } from 'src/interface/utility';
import './DefinitionList.scss';

type ExpandedDefinitionItem = { text: string | ReactElement; tooltip?: string };
type DefinitionItem =
  | null
  | undefined
  | string
  | number
  | ReactElement
  | ExpandedDefinitionItem;

const isExpandedDefinition = (
  definition: DefinitionItem,
): definition is ExpandedDefinitionItem => {
  return !!definition && definition instanceof Object && 'text' in definition;
};

type Props = {
  title: string;
  definition: DefinitionItem | DefinitionItem[];
} & OneOf<{
  dl?: never;
  heading: true;
}>;

function Definition({ title, definition, heading }: Props) {
  const GroupComponent = heading ? 'hgroup' : 'div';
  const TitleComponent = heading ? 'h6' : 'dt';
  const DefinitionComponent = heading ? 'p' : 'dd';

  return (
    <GroupComponent className="the-definition-list__item">
      <TitleComponent className="the-definition-list__title">
        {title}
      </TitleComponent>

      {Array.isArray(definition) ?
        definition.map((definition, index) => (
          <Tooltip
            key={`${definition}--${index}`}
            content={
              isExpandedDefinition(definition) ? definition?.tooltip : undefined
            }
          >
            <dd className="the-definition-list__definition">
              {isExpandedDefinition(definition) ? definition.text : definition}
            </dd>
          </Tooltip>
        ))
      : <Tooltip
          content={
            isExpandedDefinition(definition) ? definition?.tooltip : undefined
          }
        >
          <DefinitionComponent className="the-definition-list__definition">
            {isExpandedDefinition(definition) ? definition.text : definition}
          </DefinitionComponent>
        </Tooltip>
      }
    </GroupComponent>
  );
}

export default Definition;

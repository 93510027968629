export const moveItemAbove = <Item>(
  array: Readonly<Item[]>,
  from: Item,
  to: Item,
) =>
  array.flatMap((item) =>
    item === from ? []
    : item === to ? [from, to]
    : item,
  );

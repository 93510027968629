export type ServiceWorkerMessageData = {
  type: 'NAVIGATE';
  payload: string;
};

export const registerServiceWorker = () => {
  // Do not register service worker when running MSW
  // because it will break the mock server
  if (import.meta.env.VITE_MSW_ENABLED) {
    return Promise.reject();
  }

  // Needed to support vite in production and development
  // See: https://vite-pwa-org.netlify.app/guide/service-worker-without-pwa-capabilities.html#registering-of-the-service-worker-in-your-app
  return navigator.serviceWorker.register(
    import.meta.env.MODE === 'production' ?
      '/service-worker.js'
    : '/dev-sw.js?dev-sw',
    { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' },
  );
};

export const askUserPermission = async () =>
  await Notification.requestPermission();

export const createNotificationSubscription = async (
  pushServerPublicKey: string,
) => {
  const serviceWorker = await navigator.serviceWorker.ready;

  return serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey,
  });
};

export const getUserSubscription = async () => {
  const serviceWorker = await navigator.serviceWorker.ready;

  return serviceWorker.pushManager.getSubscription();
};

import { toLowerCase } from './toLowerCase';

// This separates accents from the characters, and then removes them.
// The unicode is ´ to  ͯ
const toCompareString = (text: string) =>
  toLowerCase(
    ('normalize' in String.prototype ?
      String.prototype.normalize.call(text)
    : text
    ).replace(/[-_\s\u0300-\u036f]+/g, ''),
  );

export function searchCompare(reference: string, searchTerm: string) {
  return toCompareString(reference).includes(toCompareString(searchTerm));
}

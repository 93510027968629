import {
  PublicKey,
  Subscription,
} from '@motional-cc/fe/interface/api/web-push';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi, useMutateApi } from 'src/api/hooks/service';
import { userApi } from 'src/api/user';
import { webPushPaths } from 'src/api/web-push';
import { useMessages } from 'src/components/Messages/messages-context';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import {
  askUserPermission,
  createNotificationSubscription,
  getUserSubscription,
  registerServiceWorker,
  ServiceWorkerMessageData,
} from 'src/tools/push-notifications';

type PushNotificationsState = {
  status: 'enabled' | 'disabled' | 'loading' | 'unsupported';
  enablePushNotifications: () => void;
  disablePushNotifications: () => void;
  testPushNotification: () => void;
};
const defaultPushNotificationsState: PushNotificationsState = {
  status: 'disabled',
  enablePushNotifications: () => null,
  disablePushNotifications: () => null,
  testPushNotification: () => null,
};
const PushNotificationsContext = createContext<PushNotificationsState>(
  defaultPushNotificationsState,
);
const PUSH_NOTIFICATIONS_SUBSCRIPTION_ID = 'push-notifications-subscription-id';

type Props = {
  children: ReactNode;
};

export function PushNotificationsProvider({ children }: Props) {
  const { showMessage } = useMessages();
  const navigate = useNavigate();
  const hasFssRole = userApi.useHasRole('field_support_specialist');
  const [isPushSubscriptionLoading, setIsPushSubscriptionLoading] =
    useState(true);
  const [pushSubscription, setPushSubscription] =
    useState<PushSubscription | null>(null);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(
    localStorage.getItem(PUSH_NOTIFICATIONS_SUBSCRIPTION_ID),
  );
  const { result: publicKeyResult } =
    useApi<PublicKey.PublicKeyList.ResponseBody>(
      webPushPaths.PublicKey.PublicKeyList(),
    );
  const { status: subscriptionDetailStatus } =
    useApi<Subscription.SubscriptionDetail.ResponseBody>(
      webPushPaths.Subscription.SubscriptionDetail(subscriptionId ?? ''),
      {
        enabled: !!subscriptionId,
      },
    );
  const { mutate: deleteSubscription } = useMutateApi<
    Subscription.SubscriptionDelete.ResponseBody,
    Subscription.SubscriptionDelete.RequestBody
  >(
    webPushPaths.Subscription.SubscriptionDelete(subscriptionId ?? ''),
    'DELETE',
  );
  const { mutate: testPushNotification } = useMutateApi<
    Subscription.NotificationCreate.ResponseBody,
    Subscription.NotificationCreate.RequestBody
  >(webPushPaths.Subscription.NotificationCreate(subscriptionId ?? ''), 'POST');
  const { mutate: createSubscription } = useMutateApi<
    Subscription.SubscriptionCreate.ResponseBody,
    Subscription.SubscriptionCreate.RequestBody
  >(webPushPaths.Subscription.SubscriptionCreate(), 'POST');
  const arePushNotificationsSupported = useMemo(
    () =>
      hasFssRole !== false &&
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'Notification' in window,
    [hasFssRole],
  );

  useEffect(
    function serviceWorkerMessages() {
      if (!arePushNotificationsSupported) {
        return;
      }

      const listener = (event: MessageEvent<ServiceWorkerMessageData>) => {
        const { type, payload: url } = event.data;

        if (type === 'NAVIGATE') {
          navigate(url);
        }
      };

      navigator.serviceWorker.addEventListener('message', listener);

      return () => {
        navigator.serviceWorker.removeEventListener('message', listener);
      };
    },
    [arePushNotificationsSupported, navigate],
  );

  useEffect(
    function serviceWorkerRegistration() {
      if (!arePushNotificationsSupported) {
        return;
      }

      // TODO: useEffect can run twice,
      //   so we maybe need to check if the service worker is already registered
      registerServiceWorker().catch(() => {
        showMessage({
          description:
            'Notifications failed to start up. Refresh the page if you require them.',
          type: 'error',
        });
      });
    },
    [arePushNotificationsSupported, showMessage],
  );

  useEffect(
    function watchNotificationPermissionChanges() {
      if (!arePushNotificationsSupported) {
        return;
      }

      navigator.permissions
        .query({ name: 'notifications' })
        .then((notificationPermissionStatus) => {
          notificationPermissionStatus.onchange = () => {
            setPushSubscription(null);
          };
        });
    },
    [arePushNotificationsSupported],
  );

  useEffect(
    function loadExistingPushSubscription() {
      const reloadPushSubscription = async () => {
        setIsPushSubscriptionLoading(true);
        try {
          const subscription = await getUserSubscription();

          setPushSubscription(subscription);
        } catch (_error) {
          showMessage({
            description:
              'Request for notifications failed. Refresh the page if you require them.',
            type: 'error',
          });
        }
        setIsPushSubscriptionLoading(false);
      };

      reloadPushSubscription();
    },
    [showMessage],
  );

  const enablePushNotifications = useCallback(async () => {
    const publicKey = publicKeyResult?.public_key;

    if (!publicKey) return;

    setIsPushSubscriptionLoading(true);
    try {
      const notificationsPermission = await askUserPermission();

      if (notificationsPermission !== 'granted') {
        setIsPushSubscriptionLoading(false);
        return;
      }

      const newPushSubscription =
        await createNotificationSubscription(publicKey);
      setPushSubscription(newPushSubscription);

      const { id } = await createSubscription(
        newPushSubscription.toJSON() as Subscription.SubscriptionCreate.RequestBody,
      );

      if (!id) {
        showMessage({
          description: 'Subscription ID not returned from the API',
          type: 'error',
        });
        setIsPushSubscriptionLoading(false);
        return;
      }

      setSubscriptionId(id);
      localStorage.setItem(PUSH_NOTIFICATIONS_SUBSCRIPTION_ID, id);
    } catch (_error) {
      showMessage({
        description:
          'Could not enable push notifications. Please refresh the page',
        type: 'error',
      });
    }
    setIsPushSubscriptionLoading(false);
  }, [createSubscription, publicKeyResult?.public_key, showMessage]);

  const disablePushNotifications = useCallback(async () => {
    setIsPushSubscriptionLoading(true);
    try {
      await pushSubscription?.unsubscribe();

      if (subscriptionId) {
        await deleteSubscription();
      }
      setPushSubscription(null);
      setSubscriptionId(null);
      localStorage.removeItem(PUSH_NOTIFICATIONS_SUBSCRIPTION_ID);
    } catch (_error) {
      showMessage({
        description:
          'Failed to unsubscribe from push notifications. Please refresh the page',
        type: 'error',
      });
    }
    setIsPushSubscriptionLoading(false);
  }, [deleteSubscription, pushSubscription, showMessage, subscriptionId]);

  const isPushSubscriptionIncomplete =
    (subscriptionId && isIncompleteStatus(subscriptionDetailStatus)) ||
    isPushSubscriptionLoading;
  const arePushNotificationsEnabled =
    subscriptionDetailStatus === 'success' && pushSubscription;
  const status =
    !arePushNotificationsSupported ? 'unsupported'
    : isPushSubscriptionIncomplete ? 'loading'
    : arePushNotificationsEnabled ? 'enabled'
    : 'disabled';

  const pushNotificationContextValue = useMemo(
    () =>
      ({
        status,
        enablePushNotifications,
        disablePushNotifications,
        testPushNotification,
      }) as const,
    [
      status,
      testPushNotification,
      enablePushNotifications,
      disablePushNotifications,
    ],
  );

  return (
    <PushNotificationsContext.Provider value={pushNotificationContextValue}>
      {children}
    </PushNotificationsContext.Provider>
  );
}

export function usePushSubscription() {
  const {
    status,
    enablePushNotifications,
    disablePushNotifications,
    testPushNotification,
  } = useContext(PushNotificationsContext);

  return {
    status,
    enablePushNotifications,
    disablePushNotifications,
    testPushNotification,
  } as const;
}

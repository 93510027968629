import {
  PartnerJobPreference,
  PartnerName,
  Partners,
} from '@motional-cc/fe/interface/api/vehicle-status-service';
import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useEffect, useId, useRef } from 'react';
import { useApi } from 'src/api/hooks/service';
import { fleetPaths } from 'src/api/vehicle-status-service';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import { Radio, RadioGroup } from 'src/components/common/Radio';
import Select from 'src/components/common/Select/Select';
import './SelectPartnerJobPreferences.scss';

interface Props {
  className?: string;
  placeholder?: string;
  showErrorMessage?: boolean;
  partner?: PartnerName;
  value?: PartnerJobPreference[];
  type?: 'radio' | 'select';
  disabled?: boolean;
  hideSingleOption?: boolean;
  onChange?: (newPreferences?: PartnerJobPreference[]) => void;
}

function SelectPartnerJobPreferences({
  className,
  placeholder,
  showErrorMessage,
  partner,
  value,
  type = 'select',
  disabled = false,
  hideSingleOption,
  onChange,
}: Props) {
  const name = useId();
  const autoSetRan = useRef(false);
  const { result: allowedPreferences, status } =
    useApi<Partners.GetPartnerAllowedPreferences.ResponseBody>(
      fleetPaths.Partners.GetPartnerAllowedPreferences(partner ?? ''),
      { enabled: !!partner, keepPreviousData: false },
    );

  const handleChangePartnerPreferences = (
    newValue: PartnerJobPreference | PartnerJobPreference[] | undefined,
  ) => {
    onChange?.(
      Array.isArray(newValue) ? newValue
      : newValue ? [newValue]
      : undefined,
    );
  };

  useEffect(
    function autoSetSingleValue() {
      if (
        autoSetRan.current ||
        status !== 'success' ||
        !onChange ||
        allowedPreferences?.length !== 1 ||
        isEqual(value, allowedPreferences)
      ) {
        return;
      }

      autoSetRan.current = true;
      onChange(allowedPreferences);
    },
    [status, onChange, allowedPreferences, value],
  );

  return (
    type === 'select' ?
      <Select<PartnerJobPreference>
        className={clsx([className, 'select-partner-job-preferences'])}
        label="Partner service"
        options={allowedPreferences ?? []}
        value={value?.[0]}
        onSelect={handleChangePartnerPreferences}
        showErrorMessage={showErrorMessage}
        required={
          !!(
            partner &&
            allowedPreferences?.length &&
            allowedPreferences.length > 1
          )
        }
        disabled={
          disabled || !allowedPreferences || allowedPreferences.length < 2
        }
        renderListOption={(partnerService) =>
          t(`partnerService.${partnerService}`) || partnerService
        }
        renderSelectedOption={(partnerService) =>
          t(`partnerService.${partnerService}`) || partnerService
        }
        placeholder={
          !partner ? 'Partner required'
          : status === 'error' ?
            'Error loading services'
          : ['pending', 'idle'].includes(status) ?
            'Loading…'
          : allowedPreferences?.length ?
            placeholder || 'Select service'
          : 'No service types'
        }
      />
    : ['pending', 'idle'].includes(status) ?
      <div className="select-partner-job-preferences__loader">
        <FullWidthLoader />
      </div>
    : (
      allowedPreferences?.length &&
      (!hideSingleOption || allowedPreferences.length > 1)
    ) ?
      <RadioGroup
        name={name}
        value={value?.[0]}
        onChange={handleChangePartnerPreferences}
      >
        {allowedPreferences.map((partnerService) => (
          <Radio
            key={partnerService}
            value={partnerService}
            label={t(`partnerService.${partnerService}`) || partnerService}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    : null
  );
}

export default SelectPartnerJobPreferences;

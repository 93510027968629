import { useEffect, useRef, useState } from 'react';

export const useTimeout = (
  callback: () => void,
  duration: number,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const callbackRef = useRef(callback);
  const [startedAt, setStartedAt] = useState<number>();
  callbackRef.current = callback;

  useEffect(
    function callCallbackAfterTimeout() {
      let timeoutId: number;
      if (enabled) {
        setStartedAt(Date.now());
        timeoutId = window.setTimeout(() => {
          callbackRef.current();
        }, duration);
      }
      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
      };
    },
    [enabled, duration],
  );

  return { startedAt };
};

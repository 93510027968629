import clsx from 'clsx';
import compact from 'lodash/compact';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import ButtonField from 'src/components/common/ButtonField';
import Icon, { IconName } from 'src/components/common/Icon';
import Popover from 'src/components/common/Popover';
import useElementSize from 'src/tools/hooks/useElementSize';
import './ToolBar.scss';

const DEFAULT_FIELD_WIDTH = 220;
const DEFAULT_FIELD_GAP = 16;

interface Props {
  className?: string;
  children: (ReactElement | null)[];
  toggleHiddenIcon?: IconName;
  fieldWidth?: number;
}

function ToolBar({
  className,
  children,
  toggleHiddenIcon = 'Dots1x3',
  fieldWidth = DEFAULT_FIELD_WIDTH,
}: Props) {
  const tools = useMemo(
    () => compact(Array.isArray(children) ? children : [children]),
    [children],
  );
  // useRef doesn’t work here as sometimes the component doesn’t run once the ref changes from `null`
  const [rowElement, setRowElement] = useState<HTMLDivElement | null>(null);
  const [toggleOverflowToolsButton, setToggleOverflowToolsButton] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);
  const [showOverflowTools, setShowOverflowTools] = useState(false);

  const { contentInlineSize: rowWidth } = useElementSize(rowElement);
  const toolsSpace = Math.min(rowWidth, 1000);
  const maxToolCount = Math.max(
    1,
    Math.floor(toolsSpace / (fieldWidth + DEFAULT_FIELD_GAP)),
  );
  const visibleTools = tools.slice(0, maxToolCount);

  useEffect(
    function closeExtraToolsPanelWhenEmpty() {
      if (
        showOverflowTools &&
        tools?.length &&
        visibleTools?.length &&
        tools.length <= visibleTools.length
      ) {
        setShowOverflowTools(false);
      }
    },
    [showOverflowTools, tools, visibleTools],
  );

  const handleToggleOverflowToolsButtonClick = () => {
    setShowOverflowTools((showOverflowTools) => !showOverflowTools);
  };

  if (!tools?.length) {
    return null;
  }

  return (
    <div ref={setRowElement} className={clsx(['tool-bar', className])}>
      {visibleTools}

      {visibleTools.length < tools?.length && (
        <ButtonField
          ref={setToggleOverflowToolsButton}
          label={<Icon name={toggleHiddenIcon} />}
          className="tool-bar__toggle-overflow-tools-button"
          onClick={handleToggleOverflowToolsButtonClick}
        />
      )}

      {showOverflowTools && visibleTools.length - tools.length < 0 && (
        <Popover
          placement="left-start"
          onClose={() => setShowOverflowTools(false)}
          target={toggleOverflowToolsButton}
        >
          <div className="tool-bar__overflow-tools">
            {tools.slice(visibleTools.length - tools.length)}
          </div>
        </Popover>
      )}
    </div>
  );
}

export default ToolBar;

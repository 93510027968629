import clsx from 'clsx';
import { ReactNode, useRef, KeyboardEvent } from 'react';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import LinkButton from 'src/components/common/LinkButton';
import Popover, { PopoverWidth } from 'src/components/common/Popover';
import { AutocompleteProps } from './interface';
import SelectInput from './SelectInput';
import SelectList from './SelectList';
import './SelectPopover.scss';

type Props<Option> = {
  selectedOptions: Readonly<Option[]>;
  disabledOptions?: Readonly<Option[]>;
  options: Readonly<Option[]>;
  highlightedOptionIndex: number;
  renderListOption?: (value: Option) => ReactNode;
  optionKeyPath?: string;
  anchorElement: HTMLElement | null;
  onScrollToBottom?: () => void;
  onClearSelection?: () => void;
  onClose?: () => void;
  className?: string;
  type?: 'checkbox' | 'radio';
  tooltipId?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  width?: PopoverWidth;
  searchPlaceholder?: string;
  placement?: Parameters<typeof Popover>[0]['placement'];

  onOptionSelect?: (option: Option) => void;
  onOptionHighlight?: (option: Option) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
} & Partial<AutocompleteProps>;

function SelectPopover<Option>({
  selectedOptions,
  disabledOptions,
  options,
  highlightedOptionIndex,
  renderListOption,
  optionKeyPath,
  anchorElement,
  searchInputId,
  searchText = '',
  onSearchTextChange,
  onScrollToBottom,
  onOptionHighlight,
  onOptionSelect,
  onClearSelection,
  onKeyDown,
  width = 'match-target',
  searchPlaceholder,
  onClose,
  className,
  type,
  isOpen,
  isLoading,
  tooltipId,
  placement = 'bottom-start',
}: Props<Option>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOptionSelect = (option: Option) => {
    // focus back on the input after selection
    inputRef.current?.focus();
    onOptionSelect?.(option);
  };

  return (
    <Popover
      allowTabOut
      open={isOpen}
      onClose={onClose}
      target={anchorElement}
      className={clsx(['select-popover', className])}
      contentClassName="select-popover__select-content"
      id={tooltipId}
      width={width}
      placement={placement}
    >
      {isLoading ?
        <FullWidthLoader className="select-popover__loader" />
      : <div className="select-popover__select-container" onKeyDown={onKeyDown}>
          {onSearchTextChange && (
            <SelectInput
              ref={inputRef}
              label="Search…"
              className="select-popover__header"
              inputId={searchInputId}
              value={searchText}
              onChange={onSearchTextChange}
              placeholder={searchPlaceholder}
            />
          )}

          {onClearSelection && (
            <LinkButton
              className="select-popover__clear-selection-button"
              onClick={onClearSelection}
            >
              Clear
            </LinkButton>
          )}

          <SelectList
            className="select-popover__select-list"
            selectedOptions={selectedOptions}
            disabledOptions={disabledOptions}
            options={options}
            highlightedOptionIndex={highlightedOptionIndex}
            renderListOption={renderListOption}
            optionKeyPath={optionKeyPath}
            onScrollToBottom={onScrollToBottom}
            onOptionHighlight={onOptionHighlight}
            onOptionSelect={handleOptionSelect}
            type={type}
          />
        </div>
      }
    </Popover>
  );
}

export default SelectPopover;

import brandColors, { BrandColorName } from 'src/tools/brand-colors';
import { isRgb } from './rgb';
import { rgb2Hex } from './rgb2Hex';
import { isRgba } from './rgba';
import { Color } from '.';

export type Hex = `#${string}`;

export const isHex = (color: Color): color is Hex =>
  typeof color === 'string' && color.startsWith('#');

const namedColor2Hex = (namedColor: BrandColorName): Hex =>
  brandColors[namedColor].length === 7 ?
    brandColors[namedColor]
  : `#${brandColors[namedColor]
      .slice(1)
      .replace(/./g, (char) => `${char}${char}`)}`;

export const hex = (color: Color): Hex =>
  isHex(color) ? color
  : isRgb(color) || isRgba(color) ? rgb2Hex(color)
  : namedColor2Hex(color);

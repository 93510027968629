import { hex, isHex } from './hex';
import { hex2Rgb } from './hex2Rgb';
import { isRgba } from './rgba';
import { Color } from '.';

export type Rgb = [number, number, number];

export const isRgb = (color: Color): color is Rgb =>
  Array.isArray(color) && color.length === 3;

export const rgb = (color: Color): Rgb =>
  isRgb(color) ? color
  : isRgba(color) ? [color[0], color[1], color[2]]
  : isHex(color) ? hex2Rgb(color)
  : hex2Rgb(hex(color));

import clsx from 'clsx';
import { deploymentServerApi } from 'src/api/deployment-server';
import { registrarApi } from 'src/api/registrar';
import LogoLoader from 'src/components/common/LogoLoader';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import Tooltip from 'src/components/common/Tooltip';
import { CellProps, ColumnComponentDefinition } from './interface';
import TextCell from './TextCell';

interface Props {
  platform?: string;
  isLoading?: boolean;
  isUsingProductionMap?: boolean;
  column?: ColumnComponentDefinition;
}

export function CarPlatformCell({
  platform = '',
  isLoading,
  isUsingProductionMap = true,
  column,
}: Props) {
  return isLoading ?
      <TableCell column={column} className="the-table__cell--text">
        <span className={CONTENT_CLASS}>
          <LogoLoader className="the-table__cell-loader" />
        </span>
      </TableCell>
    : <TableCell
        column={column}
        className={clsx([
          'the-table__cell--text',
          { 'the-table__cell--style-error': !isUsingProductionMap },
        ])}
      >
        <Tooltip
          content={isUsingProductionMap ? null : 'Using a non-production map'}
        >
          <span className={CONTENT_CLASS}>{platform}</span>
        </Tooltip>
      </TableCell>;
}

// Testing this would be like testing React-query
/* istanbul ignore next */
function ConnectedCarPlatformCell({ cell: carId, column }: CellProps<string>) {
  const { vehicle, status } = registrarApi.useVehicle(carId);
  const { hasProductionMap } = deploymentServerApi.useHasProductionMap(vehicle);

  return carId ?
      <CarPlatformCell
        platform={vehicle?.platform}
        isUsingProductionMap={hasProductionMap ?? true}
        isLoading={!['error', 'success'].includes(status)}
        column={column}
      />
    : <TextCell />;
}

export default ConnectedCarPlatformCell;

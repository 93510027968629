import {
  PartnerJobPreference,
  PartnerName,
} from '@motional-cc/fe/interface/api/armada';
import lyft from 'src/static/img/partner-logo/lyft.svg?react';
import motional from 'src/static/img/partner-logo/motional.svg?react';
import uberEats from 'src/static/img/partner-logo/uber-eats.svg?react';
import uber from 'src/static/img/partner-logo/uber.svg?react';
import UNKNOWN from 'src/static/img/partner-logo/UNKNOWN.svg?react';

const partnerLogos = {
  lyft,
  lyft_fake: lyft,
  uber,
  // uber_fake: this is made redundant further down
  uberEats,
  motional,
  UNKNOWN,
};

interface Props {
  className?: string;
  partnerName?: PartnerName | string;
  service?: PartnerJobPreference | string;
}

function PartnerLogo({ partnerName, service, className }: Props) {
  if (!partnerName) {
    return <UNKNOWN className={className} />;
  }

  let LogoComponent: typeof UNKNOWN | undefined;

  if (['uber', 'uber_fake'].includes(partnerName)) {
    if (service === 'goods') {
      LogoComponent = uberEats;
    } else {
      LogoComponent = uber;
    }
  } else {
    LogoComponent = partnerLogos[partnerName];
  }

  return LogoComponent ?
      <LogoComponent className={className} />
    : <UNKNOWN className={className} />;
}

export default PartnerLogo;

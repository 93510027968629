import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import uniq from 'lodash/uniq';
import { DragEvent, useState } from 'react';
import BorderedCard from 'src/components/common/Card/BorderedCard';
import { moveItemAbove } from 'src/tools/array/moveItemAbove';
import Checkbox from './Checkbox';
import Icon from './Icon';
import './SelectAndOrder.scss';

// TODO: workout how to test drag and drop

interface Props<ColumnName extends string> {
  className?: string;
  columnOrder: Readonly<ColumnName[]>;
  selectedColumns?: Readonly<ColumnName[]>;
  disabledColumns?: Readonly<ColumnName[]>;
  renderLabel?: (columnName: ColumnName) => string;
  onOrderChange?: (newOrder: ColumnName[]) => void;
  onSelectionChange?: (newOrder: ColumnName[]) => void;
  onColumnChange?: (clickedColumnName: ColumnName, isChecked: boolean) => void;
}

function SelectAndOrder<ColumnName extends string>({
  className,
  columnOrder,
  selectedColumns,
  disabledColumns,
  renderLabel,
  onOrderChange,
  onSelectionChange,
  onColumnChange,
}: Props<ColumnName>) {
  const [draggingItem, setDraggingItem] = useState<ColumnName>();
  const [hoveredItem, setHoveredItem] = useState<ColumnName>();

  const autofocusIndex = columnOrder.findIndex(
    (columnName) => !disabledColumns?.includes(columnName),
  );

  /* istanbul ignore next: see top */
  const orderColumnKeys = (columnA: ColumnName, columnB: ColumnName): number =>
    columnOrder.indexOf(columnA) - columnOrder.indexOf(columnB);

  const handleColumnCheck =
    (clickedColumnName: ColumnName) => (isChecked: boolean) => {
      onColumnChange?.(clickedColumnName, isChecked);

      // onSelectionChange will always be defined at this point
      /* istanbul ignore if */
      if (!onSelectionChange) return;

      onSelectionChange(
        uniq(
          // If a checkbox is being unchecked it means we know `selectedColumns` exists
          !isChecked && selectedColumns ?
            selectedColumns.filter(
              (selectedColumn) => selectedColumn !== clickedColumnName,
            )
          : [...(selectedColumns ?? []), clickedColumnName],
        ).sort(orderColumnKeys),
      );
    };

  /* istanbul ignore next: see top */
  const handleDragOver =
    (columnName: ColumnName) => (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setHoveredItem(columnName);
    };

  /* istanbul ignore next: see top */
  const handleDragOverEnd = () => {
    setHoveredItem(undefined);
  };

  /* istanbul ignore next: see top */
  const handleDrop = () => {
    setHoveredItem(undefined);

    if (
      !onOrderChange ||
      !draggingItem ||
      !hoveredItem ||
      draggingItem === hoveredItem
    ) {
      return;
    }

    onOrderChange(moveItemAbove(columnOrder, draggingItem, hoveredItem));
  };

  /* istanbul ignore next: see top */
  const handleDragStart = (columnName: ColumnName) => () => {
    setDraggingItem(columnName);
  };

  /* istanbul ignore next: see top */
  const handleDragEnd = () => {
    setDraggingItem(undefined);
  };

  return (
    <menu className={clsx([className, 'select-and-order'])}>
      {columnOrder.map((columnName, index) => {
        const isBeingDragged = draggingItem === columnName;
        const isDisabled =
          disabledColumns?.includes(columnName) || isBeingDragged;
        const isDraggable = !!onOrderChange && !isDisabled && index !== 0;

        return (
          <div
            key={columnName}
            draggable={isDraggable}
            onDragStart={isDraggable ? handleDragStart(columnName) : undefined}
            onDragOver={isDraggable ? handleDragOver(columnName) : undefined}
            onDragLeave={isDraggable ? handleDragOverEnd : undefined}
            onDrop={isDraggable ? handleDrop : undefined}
            onDragEnd={
              isBeingDragged || isDraggable ? handleDragEnd : undefined
            }
            className={clsx([
              'select-and-order__item-wrapper',
              {
                'select-and-order__item-wrapper--hovered':
                  columnName === hoveredItem,
                'select-and-order__item-wrapper--dragging': isBeingDragged,
                'select-and-order__item-wrapper--disabled': isDisabled,
                'select-and-order__item-wrapper--is-draggable': isDraggable,
              },
            ])}
          >
            <BorderedCard
              isDisabled={isDisabled}
              className="select-and-order__item"
            >
              {onSelectionChange && (
                <Checkbox
                  className="select-and-order__checkbox"
                  disabled={disabledColumns?.includes(columnName)}
                  checked={!!selectedColumns?.includes(columnName)}
                  onChange={handleColumnCheck(columnName)}
                  autoFocus={index === autofocusIndex}
                  label={
                    disabledColumns?.includes(columnName) ?
                      t('selectAndOrder.locked', {
                        name: renderLabel?.(columnName) || columnName,
                      })
                    : renderLabel?.(columnName) || columnName
                  }
                />
              )}

              {isDraggable && (
                <Icon className="select-and-order__drag-icon" name="Dots2x3" />
              )}
            </BorderedCard>
          </div>
        );
      })}
    </menu>
  );
}

export default SelectAndOrder;

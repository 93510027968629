import type {
  AnyLayer,
  Map as MapboxMap,
  Style as MapboxStyle,
} from 'mapbox-gl';
import type { Theme } from 'src/interface/command-center/unsorted-types';
import { supported } from '@mapbox/mapbox-gl-supported';
import { Region } from '@motional-cc/fe/interface/api/user-profile-service';
import { MAPBOX_KEY } from '@motional-cc/fe/keys';
import { captureException } from '@sentry/react';
import noop from 'lodash/noop';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useApi } from 'src/api/hooks/service';
import { userApi } from 'src/api/user';
import EmptyState from 'src/components/common/EmptyState';
import { useMessages } from 'src/components/Messages/messages-context';
import { VITE_MAPBOX_KEY } from 'src/config/env';
import { REGION_COORDS } from 'src/interface/command-center/unsorted-consts';
import { mapSdfImages } from 'src/tools/map/map-sdf-images';
import { objectEntries } from 'src/tools/object/objectEntries';
import { objectValues } from 'src/tools/object/objectValues';
import { useTheme } from './theme-context';
import { useUrlSettings } from './url-settings-context';
import 'mapbox-gl/dist/mapbox-gl.css';

export const mapsAreSupported = supported();

let showedError = false;

const THEME_STYLE_IDS: { [theme in Theme]: string } = {
  LIGHT: 'ckylp284m17wp14mdois08lyw',
  DARK: 'ckylp2mpn00n915r844v5g0md',
};

const defaultMaps: { [mapName: string]: MapboxMap | null } = {};
const defaultMapsLoading: { [mapName: string]: boolean } = {};

type MapsState = {
  createMap: (
    mapName: string,
    containerElement?: HTMLElement | null,
    options?: { region?: Region },
  ) => void;
  removeMap: (mapName: string) => void;
  maps: typeof defaultMaps;
};
const defaultState: MapsState = {
  createMap: noop,
  removeMap: noop,
  maps: defaultMaps,
};

const MapsContext = createContext<MapsState>(defaultState);

type Props = {
  children: ReactNode;
};
type TestProps = Props & {
  maps: typeof defaultMaps;
};

const mapboxKey =
  VITE_MAPBOX_KEY ||
  (globalThis.location.origin.includes('//localhost:') ? null : MAPBOX_KEY);

export function MapsTestProvider({ children, maps }: TestProps) {
  return (
    <MapsContext.Provider value={{ createMap: noop, removeMap: noop, maps }}>
      {children}
    </MapsContext.Provider>
  );
}

const getRegion = (region?: Region) =>
  (region && REGION_COORDS[region]) ??
  REGION_COORDS[
    Object.keys(REGION_COORDS)[
      Math.floor(Math.random() * Object.keys(REGION_COORDS).length)
    ]
  ];

export function MapsProvider({ children }: Props) {
  const { theme } = useTheme();
  const { LEGACY_showMessage } = useMessages();
  const [maps, setMaps] = useState(defaultMaps);
  // A separate `ueRef` is used to ensure states are always accurate
  // `useState` can become stale between render loops
  const mapsLoadingRef = useRef(defaultMapsLoading);
  const { result: themeStyle } = useApi<MapboxStyle>(
    `https://api.mapbox.com/styles/v1/motional/${THEME_STYLE_IDS[theme]}?access_token=${mapboxKey}`,
    { isExternalUrl: true, enabled: !!(mapboxKey && THEME_STYLE_IDS[theme]) },
  );
  const { currentRegion } = useUrlSettings('currentRegion');

  const createMap = useCallback<MapsState['createMap']>(
    (mapName, containerElement, { region } = {}) => {
      // check that we don't have a map currently being created with the same name.
      const initialCenter = getRegion(region || currentRegion);

      if (
        !initialCenter ||
        !containerElement ||
        !themeStyle ||
        !mapboxKey ||
        mapsLoadingRef.current[mapName]
      ) {
        return;
      }

      // Creating the map is async, we need to make sure we don't trigger creation of two maps simultaneously.
      mapsLoadingRef.current[mapName] = true;

      import('mapbox-gl').then((mapboxModule) => {
        try {
          const newMap = new mapboxModule.Map({
            accessToken: mapboxKey,
            container: containerElement,
            zoom: 11,
            attributionControl: false,
            logoPosition: 'bottom-right',
            pitchWithRotate: false,
            touchPitch: false,
            style: themeStyle,
            center: [initialCenter[1], initialCenter[0]],
            renderWorldCopies: false,
            antialias: false,
          })
            .once('idle', () => {
              if (!mapsLoadingRef.current[mapName]) return;

              mapsLoadingRef.current[mapName] = false;
              // Only set the map once after idle
              setMaps((maps) => ({
                ...maps,
                [mapName]: newMap,
              }));
            })
            .on('idle', () => {
              // Recalculate map size in case container size has changed
              newMap.resize();

              // This check is for hot reload
              if (!newMap.hasImage(mapSdfImages.routeDirection)) {
                newMap.loadImage('/route-direction.png', (error, image) => {
                  if (error) {
                    throw error;
                  }
                  if (!image) return;

                  // sdf allows the image to have its colour changed
                  newMap.addImage(mapSdfImages.routeDirection, image, {
                    sdf: true,
                  });
                });
              }
            });
        } catch (_error) {
          LEGACY_showMessage({
            type: 'error',
            text: (
              <>
                Your browser failed to create the map.
                <br />
                Please ensure your GPU drivers are up to date.
              </>
            ),
          });

          const error = _error as Error;
          captureException(error);
        }
      });
    },
    [LEGACY_showMessage, currentRegion, themeStyle],
  );

  const removeMap = useCallback((mapName: string) => {
    setMaps((maps) => ({
      ...maps,
      [mapName]: null,
    }));
  }, []);

  useEffect(
    function updateStylesPerTheme() {
      objectValues(maps).forEach((map) => {
        if (!map || !themeStyle) {
          return;
        }

        themeStyle.layers?.forEach((layer: AnyLayer) => {
          if (!('paint' in layer && typeof layer.paint === 'object')) {
            return;
          }

          if (!map?.getLayer(layer.id)) {
            // eslint-disable-next-line no-console
            console.warn(
              `Layer “${layer.id}” isn’t on the style loaded first, so was wasted from ${theme} theme style: “${themeStyle.name}”.`,
            );
            return;
          }

          objectEntries(layer.paint).forEach(([styleName, styleValue]) => {
            map.setPaintProperty(layer.id, styleName, styleValue);
          });
        });
      });
    },
    [theme, themeStyle, maps],
  );

  return (
    <MapsContext.Provider value={{ createMap, removeMap, maps }}>
      {children}
    </MapsContext.Provider>
  );
}

interface HookProps {
  name: string;
  containerElement?: HTMLElement | null | undefined;
  useUserRegion?: boolean;
}

interface HookReturn {
  map: MapboxMap | null;
  isSupported: boolean;
  fallbackComponent: ReactNode;
}

function useSupportedMap({ name, containerElement, useUserRegion }: HookProps) {
  const { createMap, maps } = useContext(MapsContext);
  const { userProfile } = userApi.useUserProfile();
  const region = userProfile?.default_location;

  useEffect(
    function recreateMap() {
      if (!containerElement || (useUserRegion && !region)) {
        return;
      }

      if (maps[name]?.getContainer() !== containerElement) {
        // Always recreate the map if the container reference changes
        // e.g. navigation back and forth
        createMap(name, containerElement, {
          region: useUserRegion ? region : undefined,
        });
      }

      return;
    },
    [createMap, name, containerElement, maps, useUserRegion, region],
  );

  return useMemo(
    () => ({ map: maps[name], isSupported: true, fallbackComponent: null }),
    [maps, name],
  );
}

function useUnsupportedMap() {
  return useMemo(
    () => ({
      map: null,
      fallbackComponent: (
        <EmptyState
          title="Your browser doesn’t appear to support maps"
          description={
            <>
              <p>In order to use pages that require maps, ensure:</p>
              <ul>
                <li>
                  <p>
                    you’re on a recent version of Chrome, Firefox, Edge, or
                    Safari
                  </p>
                </li>
                <li>
                  <p>your hardware and firmware supports WebGL.</p>
                  <p>You can check:</p>
                  <ul>
                    <li>
                      on chrome: navigate to chrome://gpu/ for “WebGL: Hardware
                      accelerated”
                    </li>
                    <li>
                      on safari desktop: in the menu bar, click Safari then
                      preferences / settings. Go to the Websites tab. If you see
                      WebGL in the left-hand list, select it and choose “Ask” or
                      “Allow” for motional.cc
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          }
        />
      ),
      isSupported: false,
    }),
    [],
  );
}

function useNoMapKey() {
  const { LEGACY_showMessage } = useMessages();

  useEffect(
    function explainToUserWhyNoMapShows() {
      if (showedError) return;

      LEGACY_showMessage({
        type: 'warning',
        text: 'No map key was entered during build',
        // this isn’t super important, so let’s not hide other errors
        overridable: true,
      });
      showedError = true;

      captureException(new Error('No MapBox key was provided.'), {
        level: 'fatal',
      });
    },
    [LEGACY_showMessage],
  );

  return useMemo(
    () => ({
      map: null,
      isSupported: mapsAreSupported,
      fallbackComponent: (
        <EmptyState title="No map key was entered during build" />
      ),
    }),
    [],
  );
}

export const useMap: (useMapProps: HookProps) => HookReturn =
  !mapboxKey ? useNoMapKey
  : !mapsAreSupported ? useUnsupportedMap
  : useSupportedMap;

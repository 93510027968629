import {
  FleetVehicle,
  Partner,
  PartnerJobPreference,
} from '@motional-cc/fe/interface/api/armada';
import clsx from 'clsx';
import compact from 'lodash/compact';
import { FunctionComponent } from 'react';
import { CellProps } from 'src/components/common/Table/interface';
import TableCell from 'src/components/common/Table/TableCell';
import { objectEntries } from 'src/tools/object/objectEntries';
import { renderPartnerLabel } from 'src/tools/translate';
import { isNullish } from 'src/tools/types';
import TextCell from './TextCell';
import './VehiclePartnersCell.scss';

interface CellPartner extends Partner {
  isActive: boolean;
  name: string;
  label: string;
  jobPreference?: PartnerJobPreference;
}

interface Props extends CellProps<FleetVehicle> {
  PartnerComponent: FunctionComponent<{ partner: CellPartner }>;
}

function VehiclePartnersCell({ cell, column, PartnerComponent }: Props) {
  if (column['valuePath']) {
    throw new Error(
      "Always pass `valuePath` as an empty string ('') when using VehiclePartnersCell",
    );
  }

  // TODO: stop filtering when data is fixed
  const partners: CellPartner[] =
    cell?.partners ?
      compact(
        objectEntries(cell.partners).flatMap(
          ([partnerName, partner]): CellPartner[] | CellPartner | null => {
            if (!partner?.carId) {
              return null;
            }

            // TODO: I can’t remember why we’re assuming this may not be an array
            const partnerPreferences = partner.jobPreferences as
              | PartnerJobPreference
              | PartnerJobPreference[];
            const partnerHasNoPreferences =
              isNullish(partnerPreferences) || !partnerPreferences.length;
            if (partnerHasNoPreferences) {
              return {
                isActive: false,
                name: partnerName,
                label: renderPartnerLabel(partnerName),
                ...partner,
              };
            }

            const listOfPartnerPreferences =
              Array.isArray(partnerPreferences) ? partnerPreferences : (
                [partnerPreferences]
              );
            return listOfPartnerPreferences.map((jobPreference) => ({
              isActive: !!(
                cell.currentPartner && cell.currentPartner === partnerName
              ),
              name: partnerName,
              label: renderPartnerLabel(partnerName, listOfPartnerPreferences),
              jobPreference,
              ...partner,
            }));
          },
        ),
      )
    : [];

  return column && partners.length > 0 ?
      <TableCell className="vehicle-partners-cell" column={column}>
        <span className="vehicle-partners-cell__partner-list">
          {partners.map((partner) => (
            <span
              key={partner.name}
              className={clsx([
                'vehicle-partners-cell__partner-item',
                {
                  'vehicle-partners-cell__partner-item--is-inactive':
                    !partner.isActive,
                },
              ])}
            >
              <PartnerComponent partner={partner} />
            </span>
          ))}
        </span>
      </TableCell>
    : <TextCell />;
}

export default VehiclePartnersCell;

import t from '@motional-cc/fe/tools/translate';
import TextCell, { isTextCellProps, Props as TextCellProps } from './TextCell';

const translateCell = (translatePrefix?: string, cell?: string | null) =>
  ((!!cell && t(`${translatePrefix}${cell}`)) || cell) ?? '';

function TranslatedCell({ cell = '', column }: TextCellProps) {
  return (
    <TextCell
      column={column}
      cell={
        isTextCellProps(cell) ?
          {
            ...cell,
            text: translateCell(
              column?.translatePrefix,
              cell.text as string | null,
            ),
          }
        : translateCell(column?.translatePrefix, cell as string | null)
      }
    />
  );
}

export default TranslatedCell;

import { PartnerName as PartnerNameAS } from '@motional-cc/fe/interface/api/api-server';
import {
  PartnerName as PartnerNameVSS,
  Partners,
} from '@motional-cc/fe/interface/api/vehicle-status-service';
import t from '@motional-cc/fe/tools/translate';
import { useApi } from 'src/api/hooks/service';
import { fleetPaths } from 'src/api/vehicle-status-service';
import Select from 'src/components/common/Select/Select';
import { renderPartnerLabel } from 'src/tools/translate';
import SelectListOptionDualLabel from './SelectListOptionDualLabel';

type PartnerSource = 'vehicle-status-service' | 'api-server';

const renderListOption = <PartnerName extends string>(
  partnerName: PartnerName,
) =>
  partnerName.endsWith('_fake') ?
    <SelectListOptionDualLabel
      primaryLabel={t(`partnerUmbrella.${partnerName}`) || partnerName}
      secondaryLabel="Disables partner integrations"
    />
  : t(`partnerUmbrella.${partnerName}`) || partnerName;

interface Props<PartnerName, PartnerSource extends string> {
  onSelect: (newValue?: PartnerName) => void;
  className?: string;
  partner?: PartnerName;
  required?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  label?: string;
  partnerSource?: PartnerSource;
  showErrorMessage?: boolean;
}

function SelectPartner<PS extends PartnerSource = 'vehicle-status-service'>({
  onSelect,
  className,
  partner,
  required,
  disabled,
  allowClear,
  label,
  showErrorMessage,
}: Props<PS extends 'api-server' ? PartnerNameAS : PartnerNameVSS, PS>) {
  const {
    result: partners,
    error,
    status,
  } = useApi<Partners.GetVehiclePartners.ResponseBody>(
    fleetPaths.Partners.GetVehiclePartners(),
  );

  const handleSelect = (newValue?: typeof partner) => {
    onSelect(newValue);
  };

  return (
    <Select
      allowClear={allowClear}
      label={label ?? 'Partner'}
      className={className}
      disabledOptions={['error', 'pending']}
      value={partner}
      onSelect={handleSelect}
      required={required}
      disabled={disabled}
      placeholder="Select partner"
      renderListOption={renderListOption}
      showErrorMessage={showErrorMessage}
      options={
        ['pending', 'idle'].includes(status) ? ['loading']
        : error ?
          ['error']
        : partners ?? []
      }
      renderSelectedOption={(partnerName) =>
        renderPartnerLabel(partnerName) || partnerName
      }
    />
  );
}

export default SelectPartner;

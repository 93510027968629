import type { StringBoolean } from 'src/interface/command-center/unsorted-types';
import {
  Region,
  RoleId,
} from '@motional-cc/fe/interface/api/user-profile-service';
import upperFirst from 'lodash/upperFirst';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  NavigateOptions,
  URLSearchParamsInit,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { DurationName } from 'src/components/DataColosseum/utils';
import { objectEntries } from 'src/tools/object/objectEntries';
import { isNullish } from 'src/tools/types';

type healthMainNavTab = 'vehicles' | 'constraints' | 'pudos';

// As these are stored in the URL, all values must extend string
export type UrlSettings = {
  currentRegion: Region;
  // Some end-points require a region, so we can’t always make regionless calls.
  // By marking this as “whenPossible” hopefully makes it clear, both:
  //  • to developers why we store both a region and a flag
  //  • to users looking at the URL that this isn’t a sure-fire way to always see all regions
  showAllRegions: 'whenPossible';
  selectedRole: RoleId;
  selectedPartners: string;
  focusedVehicleId: string;
  healthMainNavTab: healthMainNavTab;
  fleetDrawerTab:
    | healthMainNavTab
    | 'fullOverview'
    | 'filteredOverview'
    | 'focusedVehicle';
  showVehicleFilters: StringBoolean;
  showCreateFleet: StringBoolean;
  showManageFleets: StringBoolean;
  viewFleet: string;
  editFleet: string;
  vehicleInfoSections:
    | 'overview'
    | 'avHealth'
    | 'notifications'
    | 'exterior'
    | 'cameras'
    | 'rides'
    | 'interior'
    | 'versions';
  geppettoInfoSections: 'exterior' | 'interior' | 'ride';
  mobileGeppettoTab: 'vehicles' | 'focusedVehicle';
  globalNotificationsTab: 'settings' | 'alerts';
  analyticsStartDate: string;
  analyticsTimeFrame: DurationName;
  jobListDate: string;
  targetConstraintId: string;
  componentVersion: string;
  componentName: string;
  cycleId: string;
  bundleName: string;
  focusedOrgMemberNumber: string;
};
export type UrlSettingName = keyof UrlSettings;

type Props = {
  children: ReactNode;
};
type UrlSettingValue = string;

interface UrlSettingsState {
  settings: Partial<{ [settingName in UrlSettingName]: UrlSettingValue }>;
  setSettings: (settingName: string, newValue: UrlSettingValue | null) => void;
}

const UrlSettingsContext = createContext<UrlSettingsState>({
  settings: {},
  setSettings: () => true,
});

// TODO: remove this when react-router makes setSearchParams stable: https://github.com/remix-run/react-router/issues/9991
const useStableSearchParams = () => {
  const [searchParams] = useSearchParams({});

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const navigate = useNavigate();
  const setSearchParams = useCallback(
    (
      nextInit:
        | URLSearchParamsInit
        | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOptions: NavigateOptions,
    ) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === 'function' ?
          nextInit(searchParamsRef.current)
        : nextInit,
      );
      searchParamsRef.current = newSearchParams;
      navigate('?' + newSearchParams, navigateOptions);
    },
    [navigate],
  );

  return [searchParams, setSearchParams] as const;
};

export function UrlSettingsProvider({ children }: Props) {
  const [searchParams, setSearchParams] = useStableSearchParams();

  useEffect(
    // redirects url settings from the old format to the new format
    function redirectLegacySettingsParam() {
      // use window.location to only do this on initial load.
      const legacySettingsParam = new URLSearchParams(
        window.location.search,
      ).get('settings');
      if (!legacySettingsParam) return;

      const legacySettings = JSON.parse(legacySettingsParam) as
        | Partial<{
            [settingName in UrlSettingName]: UrlSettingValue;
          }>
        | undefined;
      if (!legacySettings) return;

      const searchParams = new URLSearchParams();
      for (const [key, value] of objectEntries(legacySettings)) {
        searchParams.set(key, value as string);
      }
      setSearchParams(searchParams, { replace: true });
    },
    [setSearchParams],
  );

  const setSetting = useCallback(
    <SettingName extends UrlSettingName>(
      settingName: SettingName,
      newValue: UrlSettings[SettingName] | null | undefined,
    ) => {
      setSearchParams(
        (currentSearchParams) => {
          if (isNullish(newValue)) {
            currentSearchParams.delete(settingName);
          } else {
            currentSearchParams.set(settingName, newValue as string);
          }
          return currentSearchParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const currentSettings = useMemo(() => {
    const settings: { [settingName: string]: UrlSettingValue } = {};
    for (const [key, value] of searchParams.entries()) {
      settings[key] = value;
    }
    return settings;
  }, [searchParams]);

  return (
    <UrlSettingsContext.Provider
      value={{ settings: currentSettings, setSettings: setSetting }}
    >
      {children}
    </UrlSettingsContext.Provider>
  );
}

interface GetUpdatedUrlQueryStringProps {
  set?: Partial<UrlSettings>;
  unset?: UrlSettingName[] | true;
}

export function useGetUpdatedUrlQueryString() {
  const { settings } = useContext(UrlSettingsContext);

  return useCallback(
    ({ unset, set }: GetUpdatedUrlQueryStringProps) => {
      const newSearchParams = new URLSearchParams();

      if (set) {
        for (const [key, value] of objectEntries(set)) {
          newSearchParams.set(key, value as string);
        }
      }

      if (unset !== true) {
        for (const [key, value] of objectEntries(settings)) {
          if (!set?.[key] && !unset?.includes(key)) {
            newSearchParams.set(key, value);
          }
        }
      }

      return newSearchParams.toString();
    },
    [settings],
  );
}

export function useUrlSettings<SettingName extends UrlSettingName>(
  settingName: SettingName,
) {
  type Value = UrlSettings[SettingName];
  type ValueSetter = (newValue: Value | null) => void;
  type SetterKey = `set${Capitalize<SettingName>}`;

  const { settings, setSettings } = useContext(UrlSettingsContext);

  const setThisSetting = useCallback<ValueSetter>(
    (newValue: Value | null) => setSettings(settingName, newValue),
    [setSettings, settingName],
  );

  const returnObject = useMemo(
    () => ({
      value: settings[settingName],
      setValue: setThisSetting,
      [settingName as SettingName]: settings[settingName],
      [`set${upperFirst(settingName)}` as SetterKey]: setThisSetting,
    }),
    [setThisSetting, settingName, settings],
  );

  return returnObject as Readonly<
    {
      [key in 'value' | SettingName]: Value | undefined;
    } & {
      [key in 'setValue' | SetterKey]: ValueSetter;
    }
  >;
}
